// Sign Up
export const STR_FirstName = "First name";
export const STR_Tam_FirstName = "முதற்பெயர்";

export const STR_Name_Invalid = "Invalid name. Please use only letters ";
export const STR_Tam_Name_Invalid =
  "தவறான பெயர். எழுத்துகளை மட்டும் பயன்படுத்தவும்";

export const STR_LastName = "Last name";
export const STR_Mic_Permission =
  "Microphone permission denied. Please grant permission to use the microphone.";
export const STR_Tam_Mic_Permission =
  "ஒலிவாங்கி அனுமதி மறுக்கப்பட்டது.ஒலிவாங்கி பயன்படுத்த அனுமதி வழங்கவும்.";
export const STR_Try_Again = "Try again";
export const STR_Tam_Try_Again = "மீண்டும் முயற்சி செய்";
export const STR_Tam_LastName = "கடைசி பெயர";
export const STR_Email = "Email";
export const STR_Tam_Email = "மின்னஞ்சல்";
export const STR_Password = "Password";
export const STR_Tam_Password = "கடவுச்சொல்";
export const STR_ConfirmPassword = "Confirm new password";
export const STR_Tam_ConfirmPassword = "கடவுச்சொல்லை உறுதிப்படுத்துங்கள்";
export const STR_Tam_MyAccount_ConfirmPassword =
  "புதிய கடவுச்சொல்லை உறுதிப்படுத்துங்கள்";
export const STR_Signup = "Submit";
export const STR_Tam_Signup = "சமர்ப்பிக்கவும்";
export const STR_AlreadyHaveAccount = "Already have an account?";
export const STR_Tam_AlreadyHaveAccount = "ஏற்கனவே கணக்கு உள்ளதா?";
export const STR_SignupContentEnglish = "Create new account";
export const STR_SignupContentTamil = "புதிய கணக்கை உருவாக்கு";
export const STR_Error = "Error: Invalid username / password";
export const STR_TAM_Error = "தவறு: தவறான பயனர் பெயர் / கடவுச்சொல்";
export const STR_Error_Result = "Not a registered account, Please sign Up";
export const STR_TAM_Error_Result =
  "பதிவுசெய்யப்பட்ட கணக்கு அல்ல; அன்புகூர்ந்து பதிந்துகொள்ளுங்கள்";

export const STR_PasswordEmpty = "Please input your password!";
export const STR_Tam_PasswordEmpty = "உங்கள் கடவுச்சொல்லை உள்ளீடு செய்யுங்கள்!";
export const STR_Confirm_PasswordEmpty = "Please confirm your password!";
export const STR_Tam_Confirm_PasswordEmpty =
  "உங்கள் கடவுச்சொல்லை உறுதிப்படுத்துங்கள்!";
export const STR_New_PasswordEmpty = "Please input your new password!";
export const STR_Tam_New_PasswordEmpty = "உங்கள் புதிய கடவுச்சொல்லை உள்ளிடுக!";

export const STR_ConfirmPasswordEmpty = "Please confirm your password";
export const STR_Tam_ConfirmPasswordEmpty =
  "உங்கள் கடவுச்சொல்லை உறுதிப்படுத்துங்கள்";

export const STR_EmailEmpty = "Please input your email!";
export const STR_Tam_EmailEmpty = "உங்கள் மின்னஞ்சலை உள்ளீடு செய்யுங்கள்!";
export const STR_EmailValid = "The input you entered is not a valid e-mail!";
export const STR_Tam_EmailValid =
  "நீங்கள் உள்ளீடு செய்த மின்னஞ்சல் முகவரி செல்லுபடியாகும் முகவரி அல்ல!";
export const STR_MailValid = "Please enter a valid email address!";
export const STR_Tam_MailValid = "சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும்!";
export const STR_PasswordValid =
  "The two passwords that you entered do not match!";
export const STR_Tam_PasswordValid =
  "நீங்கள் உள்ளிட்ட இரண்டு கடவுச்சொற்கள் பொருந்தவில்லை!";
export const STR_F_NameEmpty = "Please input your first name!";
export const STR_Tam_F_NameEmpty = "உங்கள் முதல் பெயரை உள்ளிடுக!";
export const STR_L_NameEmpty = "Please input your last name!";
export const STR_Tam_L_NameEmpty = "உங்கள் கடைசி பெயரை உள்ளிடுக!";
export const STR_MandatoryFields =
  "Fields marked with an asterisk are mandatory!";
export const STR_Tam_MandatoryFields =
  "நட்சத்திரத்துடன் குறிக்கப்பட்ட புலங்கள் கட்டாயமாகும்!";

//Login
export const STR_Login = "Sign in";
export const STR_Tam_Login = "உள்ளே செல்ல";
export const STR_DoNotHaveAccount = "Don't have an account?";
export const STR_Tam_DoNotHaveAccount = "கணக்கு இல்லையா?";
export const STR_RememberMe = "Remember Me";
export const STR_Tam_RememberMe = "நினைவில் வையுங்கள்";
export const STR_ForgetPassword = "Forgot Password?";
export const STR_Tam_ForgetPassword = "கடவுச்சொல்லை மறந்துவிட்டீர்களா?";
export const STR_WelcomeEnglish = "Thanks for coming";
export const STR_OTP_NOT_RECEIVE = "Didn't receive OTP code?";
export const STR_TAM_Loading = "காத்திருக்கவும் ...";
export const STR_Loading = "Loading ...";
export const STR_TAM_OTP_NOT_RECEIVE = "ஒருமுறைக் கடவுச்சொல் கிடைக்கவில்லையா?";
export const STR_WelcomeTamil = "வருகைக்கு நன்றி";
export const STR_RESEND_IT = {
  tamil: "மீண்டும் அனுப்பவும்",
  english: "Resend again",
};
export const STR_SwitchLanguage = "Switch Languages";
export const STR_Tam_SwitchLanguage = "மொழியை மாற்றவும்";
export const STR_English = "English";
export const STR_Tamil = "தமிழ்";
export const STR_Google = "Sign in with Google";
export const STR_Tam_Google = "கூகுள் மூலம் உள்நுழைக";
export const STR_Or = "or ";
export const STR_Tam_Or = "அல்லது ";
export const STR_Google_Signup = "Sign up with Google";
export const STR_Tam_Google_Signup = "கூகுள் மூலம் உள்நுழைக";

//Forget password
export const STR_SendOtp = "Send OTP";
export const STR_Tam_SendOtp = "ஒருமுறைக் கடவுச்சொல்லை அனுப்ப";
export const STR_SignIn = "or Sign in";
export const STR_Tam_SignIn = "அல்லது உள்ளே செல்ல";
export const STR_First_Name_Empty = "Please enter your First Name";
export const STR_Tam_First_name_Empty = "உங்கள் முதல் பெயரை உள்ளிடவும்";

export const STR_Tamil_Name_Empty = "Please enter your english name";
export const STR_Tam_Tamil_name_Empty = "உங்கள் ஆங்கிலப் பெயரை உள்ளிடவும்";
export const STR_Choose_Name = "Please choose your tamil name";
export const STR_Tam_Choose_Name =
  "தயவு செய்து உங்கள் தமிழ் பெயரை தேர்வு செய்யவும்";
export const STR_AccountRecovery = "Account Recovery";
export const STR_Tam_AccountRecovery = "கணக்கை மீட்டெடுக்க";
//OTP Screen
export const STR_Otp = "OTP";
export const STR_Tam_Otp = "ஓடீபி";
export const STR_VerifyOtp = "Verify OTP";
export const STR_Tam_VerifyOtp = "ஒருமுறைக் கடவுச்சொல்லைச் சரிபாருங்கள்";
export const STR_EnterOtp = "Please enter your OTP number";
export const STR_Tam_EnterOtp = "ஓடீபி ஐ உள்ளிடவும்";
export const STR_OtpEmpty = "Please input your OTP number";
export const STR_Tam_OtpEmpty = "உங்கள் ஓடீபி ஐ உள்ளிடவும்";
export const STR_OtpVerify = "OTP Verification";
export const STR_Tam_OtpVerify = "ஒருமுறைக் கடவுச்சொல்லை சரிபார்த்தல்";
export const STR_SetNewPassword = "Set New Password";
export const STR_Tam_SetNewPassword = "புதிய கடவுச்சொல்லை அமை";
//Change password
export const STR_ChangePassword = "Change Password";
export const STR_Tam_ChangePassword = "கடவுச்சொல்லை மாற்ற";
export const STR_NewChangePassword = "Please enter new password";
export const STR_Tam_NewChangePassword = "புதிய கடவுச்சொல்லை உள்ளிடவும்";
export const STR_PasswordMismatch = "Passwords mismatch";
export const STR_Tam_PasswordMismatch = "கடவுச்சொற்கள் பொருந்தவில்லை";

//Header
export const STR_MyAccount = "My Account";
export const STR_Tam_MyAccount = "என் கணக்கு";
export const STR_LogOut = "Sign Out";
export const STR_Tam_LogOut = "வெளியேறு";
export const STR_Home = "Home";
export const STR_Tam_Home = "முகப்பு";
export const STR_About = "About Us";
export const STR_Tam_About = "எங்களைப் பற்றி";

//Search
export const STR_SEARCH_CLEAR = "Clear";
export const STR_TAm_SEARCH_CLEAR = "ரத்து செய்";

//Home
export const STR_Agarathi = "Min Agaram";
export const STR_Tam_agarathi = "மின் அகரம்";
export const STR_Welcome = "Welcome";
export const STR_Tam_Welcome = "வணக்கம்";
export const STR_Welcome_Para =
  "Your gateway to mastering Tamil, a language of poetic elegance and profound history.";
export const STR_Tam_Welcome_Para =
  "கவிதை நயமும் ஆழ்ந்த வரலாறும் கொண்ட தமிழ் மொழியில் தேர்ச்சி பெற உங்களுக்கான நுழைவாயில்.";
export const STR_Explore_Btn = "Explore Tamil";
export const STR_Tam_Explore_Btn = "தமிழை ஆராயுங்கள்";
export const STR_Showing = "Showing";
export const STR_Tam_Showing = "காட்டுகிறோம்";
export const STR_Words = "Words";
export const STR_Tam_Words = "சொற்கள்";
export const STR_Experience = "Experience our app in your phone";
export const STR_Tam_Experience =
  "உங்கள் தொலைபேசியில் எங்கள் பயன்பாட்டை அனுபவிக்கவும்";
export const STR_DownloadFrom = "Download from";
export const STR_Tam_DownloadFrom = "இருந்து பதிவிறக்க";
export const STR_AppStore = "App Store";
export const STR_PlayStore = "Play Store";
export const STR_WordOfDay = "Word of the Day";
export const STR_Tam_WordOfDay = "தினம் ஒரு சொல்";
export const STR_Featured = "Trending";
export const STR_Tam_Featured = "பிரபலமாக உள்ளவை";
export const STR_Recent = "Recently Seen Words";
export const STR_Tam_Recent = "அண்மையில் பார்த்த சொற்கள்";
export const STR_En = "en.";
export const STR_Search = "Search Word";
export const STR_Tam_Search = "வார்த்தையைத் தேடுக";
export const STR_Word_Details = "Word Data";
export const STR_Tam_Word_Details = "வார்த்தை தரவு";
//About
export const STR_Content1 = "An Electronic Dictionary";
export const STR_Tam_Content1 = "ஒரு மின்னணு அகராதி";
export const STR_Content =
  "Agarathi is the best Tamil Dictionary, it has the latest and most comprehensive coverage of Tamil vocabulary.";
export const STR_Tam_Content =
  "அகராதி சிறந்த தமிழ் அகராதி, இது தமிழ் சொற்களஞ்சியத்தின் சமீபத்திய மற்றும் விரிவான தகவல்களைக் கொண்டுள்ளது.";
export const STR_DownloadNow = "Download Now";
export const STR_Tam_DownloadNow = "இப்போது பதிவிறக்கவும்";
export const STR_Context = "Context";
export const STR_Tam_Context = "சூழல்";
export const STR_Title = "Title";
export const STR_Tam_Title = "தலைப்பு";
//footer
export const STR_Developer = "Developed by";
export const STR_Cosmic = "Cosmic Consultancy Services Pvt. Ltd.";
export const STR_CosmicInfo =
  "Cosmic Consultancy PTE. LTD. is a leading Singapore base Information and Technology Company.";
//Saved
export const STR_Saved = "Saved Words";
export const STR_Tam_Saved = "சேமிக்கப்பட்ட சொற்கள்";
export const STR_Saved_Message = "Successfully Saved";
export const STR_Tam_Saved_Message = "அமைப்புகள் வெற்றிகரமாக சேமிக்கப்பட்டன";
export const STR_UnSaved_Message = "Successfully Removed";
export const STR_Tam_UnSaved_Message = "அமைப்புகள் வெற்றிகரமாக அகற்றப்பட்டன";

//Settings
export const STR_Settings = "Settings";
export const STR_Tam_Settings = "அமைப்புமுறை";
export const STR_PleaseSelectAtLeastOneOptionToProceed =
  "Please select at least one option to proceed.";
export const STR_Tam_PleaseSelectAtLeastOneOptionToProceed =
  "தொடர குறைந்தபட்சம் ஒரு விருப்பத்தைத் தேர்ந்தெடுக்கவும்.";

//WordCard
export const STR_Invalid_PWD_Type =
  "Password must contain at least 8 characters, including one uppercase letter,  lowercase letter , number , symbol";
export const STR_Invalid_PWD_Type_Tamil =
  "கடவுச்சொல்லானது ஒரு பேரெழுத்து, ஒரு சிற்றெழுத்து, எண், குறியீடு ஆகியவை உள்ளிட்ட குறைந்தது எட்டு உருக்களைக் கட்டாயம் கொண்டிருக்கவேண்டும்";
//Profile
export const STR_EditAccount = "Edit your account";
export const STR_Tam_EditAccount = "உங்கள் கணக்கை திருத்த";
export const STR_UpdateAccount = "Update your account";
export const STR_Tam_UpdateAccount = "உங்கள் கணக்கைப் புதுப்பிக்க";
export const STR_ProfileUpdate = "Profile Update";
export const STR_Tam_ProfileUpdate = "சுயவிவர புதுப்பிப்பு";
export const STR_ResetPassword = "Change Password";
export const STR_Tam_ResetPassword = "கடவுச்சொல்லை மாற்ற";
export const STR_CurrentPassword = "Current password";
export const STR_Tam_CurrentPassword = "தற்போதைய கடவுச்சொல்";
export const STR_NewPassword = "New password";
export const STR_Tam_NewPassword = "புதிய கடவுச்சொல்";
export const STR_Edit_TamilName = "Update Tamil Name";
export const STR_Tam_Edit_TamilName = "தமிழ்ப் பெயரைப் புதுப்பிக்க";
export const STR_Edit_ProfileName = "Full Name Updated Successfully";
export const STR_Tam_Edit_ProfileName =
  "முழுப்பெயர் வெற்றிகரமாக புதுப்பிக்கப்பட்டது";

//SearchView
export const STR_RelatedWords = "Related Words";
export const STR_Tam_RelatedWords = "தொடர்புடைய சொற்கள்";
export const STR_NoResults = " not found";
export const STR_Tam_NoResults = " கிடைக்கவில்லை";

//Mic
export const STR_RelatedWordsFor = "Related Words for";
export const STR_Tam_RelatedWordsFor = "தொடர்புடைய சொற்கள்";

//MyAccount
export const STR_CurrentPasswordEmpty = "Please input your current password!";
export const STR_Tam_CurrentPasswordEmpty =
  "உங்கள் தற்போதைய கடவுச்சொல்லை உள்ளிடுக!";
export const STR_NewPasswordEmpty = "Please input your new password.";
export const STR_Tam_NewPasswordEmpty = "உங்கள் புதிய கடவுச்சொல்லை உள்ளிடவும்.";
export const STR_Confirm_New_PasswordEmpty =
  "Please confirm your new password!";
export const STR_Tam_Confirm_New_PasswordEmpty =
  "உங்கள் புதிய கடவுச்சொல்லை உறுதிப்படுத்துங்கள்!";
export const STR_PasswordValidation =
  "Password should be at least 8 to 15 characters long.";
export const STR_TAM_PasswordValidation =
  "கடவுச்சொல் குறைந்தது 8 முதல் 15 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்.";
export const STR_Save = "Save";
export const STR_UNSave = "Unsave";
export const STR_Tam_Save = "சேமி";
export const STR_Cancel = "Cancel";
export const STR_Discard = "Discard";
export const STR_Tam_Discard = "நிராகரிக்க";
export const STR_Tam_Cancel = "ரத்துசெய்";
export const STR_SamePasswordValid =
  "Current Password and New password should not be same";
export const STR_Tam_SamePasswordValid =
  "தற்போதைய கடவுச்சொல் மற்றும் புதிய கடவுச்சொல் ஒரே மாதிரியாக இருக்கக்கூடாது";
export const STR_PrivacyPolicy = "Privacy policy";
export const STR_Tam_PrivacyPolicy = "தனியுரிமைக் கொள்கை";
export const STR_Services = "Terms of services";
export const STR_Tam_Services = "சேவை விதிமுறைகள்";
export const STR_Submit = "Submit";
export const STR_Tam_Submit = "சமர்ப்பிக்கவும்";
export const STR_Bug = "Data Accuracy";
export const STR_Tam_Bug = "தரவு உறுதியாக்கம்";
export const STR_Feature = "Feature";
export const STR_Tam_Feature = "அம்சம்";
export const STR_Interface = "UI Interface";
export const STR_Tam_Interface = "பயனர் அமைப்பு";
export const STR_Others = "Others";
export const STR_Tam_Others = "மற்றவைகள்";
export const STR_Suggestion = "Please select your option";
export const STR_Tam_Suggestion = "உங்கள் விருப்பத்தை தேர்ந்தெடுக்கவும்";
export const STR_Opinion = "Your feedback is valuable to us!";
export const STR_Tam_Opinion = "உங்கள் கருத்து எங்களுக்கு முக்கியமானது!";
export const STR_Upload_Issue = "Add file (Optional)";
export const STR_Tam_Upload_Issue = "விருப்பமான கோப்பைச் சேர்க்கவும்";
export const STR_Upload = "Upload file";
export const STR_Tam_Upload = "கோப்பை பதிவேற்றவும்";
export const STR_DragNDrop = "Click or drag file to this area to upload";
export const STR_Tam_DragNDrop =
  "பதிவேற்ற, இந்தப் பகுதிக்கு கோப்பைக் கிளிக் செய்யவும் அல்லது இழுக்கவும்";
export const STR_DND_Allowed = "Only .jpeg, .jpg, .png files are allowed";
export const STR_Tam_DND_Allowed =
  ".jpeg, .jpg, .png கோப்புகள் மட்டுமே அனுமதிக்கப்படும்";
export const STR_Upload_Btn = "Upload a Image";
export const STR_Tam_Upload_Btn = "ஒரு படத்தை பதிவேற்றவும்";
export const STR_Tam_CommentsTitle = "கருத்துகளைச் சேர்க்க விரும்புகிறீர்களா?";
export const STR_CommentsEmpty = "Please enter your feedback";
export const STR_Tam_CommentsEmpty = "தயவுசெய்து உங்கள் கருத்தை உள்ளிடவும்";
export const STR_Feedback = "Feedback";
export const STR_Tam_Feedback = "கருத்து";
export const STR_FeedbackPlaceholder = "Please enter your feedback";
export const STR_Tam_FeedbackPlaceholder =
  "உங்கள் கருத்து மிகவும் முக்கியமாகும்";
export const STR_NoAudio_Eng = "No audio found";
export const STR_NoAudio_Tam = "ஆடியோ எதுவும் கிடைக்கவில்லை";
export const STR_Image_Format =
  "Invalid file format. Please select JPEG or PNG or JPG files.";
export const STR_Tam_Image_Format =
  "தவறான கோப்பு வடிவம். JPEG அல்லது PNG அல்லது JPG கோப்புகளைத் தேர்ந்தெடுக்கவும்.";

export const STR_Meaning_Setting = "Select Level";
export const STR_Tam_Meaning_Setting = "நிலைகளைத் தேர்ந்தெடுக்கவும்";

export const STR__Profile_Menu_S = "Saved";
export const STR_Tam__Profile_Menu_S = "சேமிக்கப்பட்டவை";
export const STR_Tam_Profile_Menu_R = "அண்மையில் பார்த்தவை";
export const STR__Profile_Menu_R = "Recently Seen";

export const STR_Setting_Save = "Save";
export const STR_Tam_Setting_Save = "சேமி";
export const STR_Tam_Copied = "நகலெடுக்கப்பட்டது.";
export const STR_Eng_Copied = "copied.";
export const STR_Horizontal = "Horizontal";
export const STR_Tam_Horizontal = "கிடைநிலை";
export const STR_Vertical = "Vertical";
export const STR_Tam_Vertical = "செங்குத்து";
//saved words
export const STR_NoSavedWords = "No words saved";
export const STR_Tam_NoSavedWords = "சொற்கள் எதுவும் பத்திரப்படுத்தப்படவில்லை";
export const STR_NoSavedDescription =
  "Your saved items list is currently empty. Don't worry; you can  start saving your favorite items for quick access later!";
export const STR_Tam_NoSavedDescription =
  "உங்கள் சேமிக்கப்பட்ட சொற்களின் பட்டியல் தற்போது காலியாக உள்ளது. கவலைப்படாதீர்கள்; நீங்கள் உங்களின் விரைவான அணுகலுக்கு தேவையான சொற்களை சேமிக்க தொடங்கவும்!";

//rateUs
export const STR_Tam_rateUs = "மதிப்பீடு செய்யவும்";
export const STR_rateUs = "Rate Us";

//Settings
export const STR_Tam_Settings_Updated =
  "அமைப்புகள் வெற்றிகரமாக புதுப்பிக்கப்பட்டன.";
export const STR_Settings_Updated = "Settings updated successfully.";
// Level-1
export const STR_Tam_Elementary = "அடிப்படை";
export const STR_Elementary = "Elementary";
export const STR_Tam_Grammatical = "இலக்கண வகைப்பாடு";
export const STR_Eng_Grammatical = "Grammer";
export const STR_Tam_CoreSense = "அடிப்படைப் பொருண்மை";
export const STR_Eng_CoreSense = "Core_Sense";
export const STR_Tam_Example = "எடுத்துக்காட்டு";
export const STR_Eng_Example = "Example";
// Level-2
export const STR_Tam_Intermediate = "இடைநிலை";
export const STR_Intermediate = "Intermediate";
export const STR_Tam_Synonym = "இணைச்சொல்";
export const STR_Eng_Synonym = "Synonyms";
export const STR_Tam_Antonym = "எதிர்ச்சொல்";
export const STR_Eng_Antonym = "Antonyms";
export const STR_Tam_Homophones = "ஒலி வேறுபாட்டுச் சொல்";
export const STR_Eng_Homophones = "Homophones";
export const STR_Tam_Phrasemes = "இணைமொழி";
export const STR_Phrasemes = "Phrasemes";
export const STR_Tam_Metaphor = "உவமைத்தொடர்";
export const STR_Metaphor = "Metaphors";
// Level-3
export const STR_HighLevel = "Advanced";
export const STR_Tam_HighLevel = "உயர்நிலை";
export const STR_Tam_Etymology = "அயற்சொல்";
export const STR_Eng_Etymology = "Proto-language in Etymology";
export const STR_Tam_ScientificBackground = "கலைச்சொல் விளக்கம்";
export const STR_Eng_ScientificBackground =
  "Scientific Background in Coinage of Technical Terms";

export const STR_ComingSoon = "Coming soon....";
export const STR_Tam_ComingSoon = "விரைவில் வருகிறது....";
// Next & Previous button
export const STR_Next = "Next Page";
export const STR_Tam_Next = "அடுத்த பக்கம்";
export const STR_Previous = "Previous Page";
export const STR_Tam_Previous = "முந்தைய பக்கம்";
// current password
export const STR_CurrentPasswordWrong = "Error: Current password is incorrect";
export const STR_Tam_CurrentPasswordWrong = "பிழை: தற்போதைய கடவுச்சொல் தவறானது";

export const STR_submitting_Errormsg =
  "New password should be different from the old password.";
export const STR_Tam_submitting_Errormsg =
  "புதிய கடவுச்சொல் பழைய கடவுச்சொல்லிலிருந்து வேறுபட்டதாக இருக்க வேண்டும்.";
export const STR_Tam_SeeAll = "அனைத்தையும் பார்க்க";
export const STR_Eng_SeeAll = "See All";
export const STR_Eng_Recent_Header = "Recently Seen Words";
export const STR_Tam_Recent_Header = "அண்மையில் பார்த்த சொற்கள்";

//Featured
export const STR_Tam_Featured_UnSaved_Message = "வெற்றிகரமாக நீக்கப்பட்டது";
export const STR_Tam_Featured_Saved_Message =
  "வெற்றிகரமாகச் சேமிக்கப்பட்டது.";
export const STR_Tam_Listen = "குரல்";
export const STR_Listen = "Voice";
export const STR_Copy = "Copy";
export const STR_Share_Copy = "Copy the site link";
export const STR_Copy_TO_CLIPBOARD = "Link is copied.";
export const STR_Tam_Copy_TO_CLIPBOARD = "இணையமுகவரி நகலெடுக்கப்பட்டது.";
export const STR_Tam_Copy = "நகல்";
export const STR_Tam_ToSave = "சேமி";
export const STR_Tam_UNSave = "நீக்க";
export const STR_Tam_Share = "பகிர்";
export const STR_Share = "Share";
export const STR_Tam_Share_Copy = "இணையமுகவரியை நகலெடுக்க";
export const STR_MultipleMeanings = "Multiple Meanings";
export const STR_Tam_MultipleMeanings = "பல அர்த்தங்கள்";
export const STR_GoogleCredentialsCannotBeUsed =
  "Google credentials cannot be used.";
export const STR_Tam_GoogleCredentialsCannotBeUsed =
  "Google நற்சான்றிதழ்களைப் பயன்படுத்த முடியாது";
export const STR_Tam_Keyboard = "விசைப்பலகை";
export const STR_Keyboard = "Search by voice";
export const STR_Tam_VirtualKeyboard = "மெய்நிகர் விசைப்பலகை";
export const STR_Tam_VRKeyboard_Feedback_Activate =
  "மெய்நிகர் விசைப்பலகையை இயக்கவும்";
export const STR_Tam_VRKeyboard_Feedback_DeActivate =
  "மெய்நிகர் விசைப்பலகையை செயலிழக்கச் செய்யவும்";
export const STR_Tam_On = "செயல்படுத்த";
export const STR_Tam_Off = "செயலிழக்க";
export const STR_Tam_Size_validation =
  "கோப்பு 1MB ஐ விட சிறியதாக இருக்க வேண்டும்!";
export const STR_Size_validation = "File must be smaller than 1MB!";
export const STR_Tam_Search_title =
  "நீங்கள் ஒரு தமிழ் வார்த்தையைக் கண்டாலோ அல்லது கேட்டாலோ, அதன் பொருளை அறிய";
export const STR_Search_title =
  "If you spot or hear a Tamil word, check it's meaning.";

export const STR_Tam_SearchByVoice = "குரல்வழித் தேடுக";
export const STR_SeeMore = "See More";
export const STR_Tam_SeeMore = "மேலும் பார்க்க";
export const STR_Tam_Copyrights = "காப்புரிமைகள்";
export const STR_Copyrights = "Copyright";
export const STR_Tam_TC = "விதிமுறைகளும் நிபந்தனைகளும்";
export const STR_Tam_PP = "தனியுரிமைக் கொள்கை";
export const STR_Tc = "Terms and Conditions";
export const STR_PP = "Privacy Policy";
export const STR_List_Data = "Words in the Dictionary";
export const STR_Tam_List_Data = "அகராதியில் உள்ள சொற்கள்";

export const STR_Password_Requirement =
  "Password must contain atleast one lowercase, one uppercase, one numeric, and one special character.";
export const STR_TAM_Password_Requirement =
  "கடவுச்சொல்லில் குறைந்தபட்சம் ஒரு சிறிய எழுத்து, ஒரு பெரிய எழுத்து, ஒரு எண் மற்றும் ஒரு சிறப்பு எழுத்து இருக்க வேண்டும்.";

export const STR_FullName = "Full name";
export const STR_Tam_FullName = "முழுமையான பெயர்";
export const STR_FullName_Empty = "Please input your full name!";
export const STR_Tam_FullName_Empty = "உங்கள் முழுப்பெயரை உள்ளிடு செய்யுங்கள்!";
export const STR_RealName_Empty = "Please select your full name in Tamil.";
export const STR_Tam_RealName_Empty =
  "உங்கள் முழுப் பெயரை தமிழில் தேர்ந்தெடுக்கவும்.";
export const STR_FullName_Pattern =
  "Please enter only in English letters & numbers!";
export const STR_Tam_FullName_Pattern =
  "ஆங்கில எழுத்துக்கள் மற்றும் எண்களில் மட்டும் உள்ளிடவும்!";

export const STR_NoRecentWords = "No words seen";
export const STR_Tam_NoRecentWords = "சொற்கள் எதுவும் பார்க்கவில்லை";
export const STR_NoRecent_Desccription =
  "It looks like you haven't viewed any content recently. Explore our website to discover interesting articles, products, and more.";
export const STR_Tam_NoRecent_Description =
  "நீங்கள் சமீபத்தில் எந்த உள்ளடக்கத்தையும் பார்க்கவில்லை போல் தெரிகிறது. ஆராயுங்கள் எங்கள் வலைத்தளம் சுவாரஸ்யமான கட்டுரைகள் மற்றும் தயாரிப்புகளுக்கு உதவுகிறது.";

export const STR_Accountdelete = "Delete Account";
export const STR_Tam_Accountdelete = "கணக்கை நீக்குக";
export const STR_AccountdeleteBtn = "Submit";
export const STR_Tam_AccountdeleteBtn = "சமர்ப்பிக்கவும்";
export const STR_AccountDeleteConfirm =
  "If you confirm deletion, all related information will be permanently removed, and changes cannot be undone.";
export const STR_Tam_AccountDeleteConfirm =
  "நீக்கத்தை உறுதிப்படுத்தினால், தொடர்புடைய தகவல்கள் அனைத்தும் நிரந்தரமாக நீக்கப்படும், மாற்றங்களை மீண்டும் செய்ய முடியாது.";

export const STR_DeleteReason =
  "Please provide the reason for your account deletion.";
export const STR_Tam_DeleteReason =
  "உங்கள் கணக்கை நீக்குவதற்கான காரணத்தை வழங்கவும்.";

export const STR_PopConfirmTitle = "Delete your account?";
export const STR_Tam_PopConfirmTitle = "உங்கள் கணக்கை நீக்க வேண்டுமா?";

export const STR_DeleteConfirm =
  "This would delete your personal and saved data " +
  "permanently from our database. You'll lose access " +
  "to Min Agaram Application. This action cannot be undone.";
export const STR_Tam_DeleteConfirm =
  "இது உங்கள் தனிப்பட்ட மற்றும் சேமிக்கப்பட்ட தரவுகளை, எங்கள் " +
  "தரவுத்தொகுப்பில் இருந்து நிரந்தரமாக நீக்கப்படும். " +
  "மின் அகரம் செயலியை அனுகவும் நடவடிக்கையை மீட்டமைக்கவும் முடியாது.";

export const STR_Deletevalidate = "Minimum 5 characters";
export const STR_Tam_Deletevalidate = "குறைந்தபட்சம் 5 எழுத்துகள்";

export const STR_Tam_EDIT_MSG =
  "உங்கள் தமிழ் பெயரைத் கணக்கு அமைப்புகளில் திருத்தவும்";
export const STR_EDIT_MSG = "Edit your Tamil name in 'Account Settings'";

export const STR_fullname_tamil = "Full name in Tamil";
export const STR_Tam_fullname_tamil = "உங்கள் முழு பெயர் தமிழில்";

export const STR_TAMIL_CONFIRM = "உறுதி";
export const STR_CONFIRM = "Confirm";

export const STR_TAMIL_Disclaimer = "பொறுப்புத் துறப்பு";
export const STR_Disclaimer = "Disclaimer";

export const STR_VERSION = "Version";
export const STR_TAM_VERSION = "பதிப்பு";

//Download App
export const STR_SharethisApp = "Share this App";
export const STR_Tam_SharethisApp = "இச்செயலியைப் பகிர்க";
export const STR_text1_STA = "Your Ultimate Tamil Language Companion.";
export const STR_Tam_text1_STA = "உங்கள் உன்னத தமிழ் மொழி துணை.";
export const STR_text2_STA =
  "Embark on a linguistic journey with the Min Agaram app, crafted meticulously in Singapore. Boasting over 50,000 words, this app is your gateway to mastering Tamil through a comprehensive, multi-layered learning approach.";
export const STR_Tam_text2_STA =
  "மின் அகரம் செயலியுடன் உங்கள் மொழி பயணத்தைத் தொடங்குங்கள், இது சிங்கப்பூரில் மிகுந்த கவனத்துடன் உருவாக்கப்பட்டது. 50,000க்கும் மேற்பட்ட வார்த்தைகளை கொண்ட இந்த செயலி, தமிழ் மொழியை முறைப்படியான, பல அடுக்குகளுடன் கற்றுக்கொள்ள எளிய வழியை வழங்குகிறது.";
export const STR_text3_STA =
  "Download the Min Agaram App today and start your path to fluency in one of the world's oldest and richest languages.";
export const STR_Tam_text3_STA =
  "மின் அகரம் செயலியை இன்றே பதிவிறக்கம் செய்து, உலகின் பழமையான மற்றும் பெரும்பான்மையுடைய மொழிகளில் மொழி நிபுணத்துவத்தை அடைய உங்கள் பயணத்தைத் தொடங்குங்கள்.";
export const STR_Copy_STA = "Copy Link";
export const STR_Tam_Copy_STA = "இணைப்பை நகலெடுக்கவும்";
export const STR_Copy_Success_STA = "Link is copied.";
export const STR_Tam_Copy_Success_STA =
  "இணையமுகவரி நகலெடுக்கப்பட்டது.";
export const STR_Copy_Failure_STA = "Failed to copy the link.";
export const STR_Tam_Copy_Failure_STA = "இணைப்பை நகலெடுக்க முடியவில்லை.";
//login page
export const STR_Tam_BySignin =
  "உள்நுழைவதன் மூலம் நீங்கள் எங்களுடையதை ஒப்புக்கொள்கிறீர்கள்";
export const STR_BySignin = "By signing in, you agree to our";

//Feedback page
//Positive-Feedback
export const STR_Heading_PF = "How was your overall experience with Minagaram?";
export const STR_Tam_Heading_PF =
  "மின் அகரத்தை பயன்படுத்திய உங்கள் மொத்த அனுபவம் எப்படி இருந்தது?";
export const STR_Tooltip1_PF = "Very dissatisfied";
export const STR_Tam_Tooltip1_PF = "மிகவும் அதிருப்தி";
export const STR_Tooltip2_PF = "Dissatisfied";
export const STR_Tam_Tooltip2_PF = "அதிருப்தி";
export const STR_Tooltip3_PF = "Neutral";
export const STR_Tam_Tooltip3_PF = "நடுநிலை";
export const STR_Tooltip4_PF = "Satisfied";
export const STR_Tam_Tooltip4_PF = "திருப்தி";
export const STR_Tooltip5_PF = "Very satisfied";
export const STR_Tam_Tooltip5_PF = "மிக திருப்தி";
export const STR_Tam_Alertmsg1_PF =
  "தயவு செய்து புலங்களில் ஏதேனும் ஒன்றைத் தேர்ந்தெடுக்கவும்";
export const STR_Alertmsg1_PF = "Please choose any of the fields";
export const STR_Tam_Alertmsg2_PF =
  "தயவு செய்து பின்னூட்ட கருத்துகளை வழங்கவும்";
export const STR_Alertmsg2_PF = "Please provide the feedback comments";
export const STR_Tam_Alertmsg3_PF =
  "ரேடியோ பட்டனில் ஏதேனும் ஒன்றைத் தேர்ந்தெடுக்கவும்";
export const STR_Alertmsg3_PF = "Please select any one of the radio button";
//offline component
export const STR_Tam_Offline1 = "இணைய இணைப்பு இல்லை";
export const STR_Offline1 = "No Internet Connection";
export const STR_Tam_Offline2 =
  "தயவுசெய்து உங்கள் இணைப்பை சரிபார்த்து மீண்டும் முயற்சிக்கவும்.";
export const STR_Offline2 = "Please check your connection and try again.";

//facebook sidemenu
export const STR_Facebook = "Facebook";

// Figurative Language
export const STR_Tam_Figurative_Language = "உருவக மொழி";
export const STR_Figurative_Language ="Figurative Language";
//Idioms.js
export const STR_Tam_Idioms_heading = "மரபுத்தொடர்கள்";
export const STR_Tam_Idioms = "மரபுத்தொடர்";
export const STR_Idioms = "Idioms";
export const STR_searchIdioms_Placeholder = "Search Idiom";
export const STR_Tam_searchIdioms_Placeholder = "மரபுத்தொடரைத் தேடுக";
export const STR_Tam_idioms_Iconbtn1 = "குரல்";
export const STR_idioms_Iconbtn1 = "Voice";
export const STR_Tam_idioms_Iconbtn2 = "நகல்";
export const STR_idioms_Iconbtn2 = "Copy";
export const STR_Tam_idioms_Voice = "விரைவில் வருகிறது";
export const STR_idioms_Voice = "Coming Soon";

//Metaphor.js
export const STR_Tam_Metaphor_heading = "உவமைத்தொடர்கள்";
export const STR_searchMetaphor_Placeholder = "Search Metaphor";
export const STR_Tam_searchMetaphor_Placeholder = "உவமைத்தொடரைத் தேடுக";

//Phrasemes.js
export const STR_Tam_Phrasemes_heading = "இணைமொழிகள்";
export const STR_searchPhrasemes_Placeholder = "Search Phraseme";
export const STR_Tam_searchPhrasemes_Placeholder = "இணைமொழியைத் தேடுக";

//Feedback Center
export const STR_Tam_FeedbackCenter = "பின்னூட்ட மையம்";
export const STR_FeedbackCenter = "Feedback Center";

//Word Hyperlink
export const STR_WordHyperlink_Errormsg = "not found";
export const STR_Tam_WordHyperlink_Errormsg = "கிடைக்கவில்லை";